.pdf-preview {
    width: 100%;
    aspect-ratio: 8.5/11;
    border: none;
}

.section-title {
    background-color: var(--secondary);
    color: var(--light);
    padding: 5px 10px;
    /* margin: 15px 0; */
    margin-top: 50px;
    margin-bottom: 15px;
}

.section-item {
    padding: 0 10px;
    margin-bottom: 20px;
}

.em {
    font-style: italic;
}

.top {
    /* float: right; */
    margin: 5px 10px 5px 10px;
    padding: 2px 5px;
    text-decoration: none;
    color: var(--light);
    background-color: var(--primary);
    border-radius: 5px;
    font-size: 1.5em;
}
.top:hover {
    text-decoration: none;
    color: var(--secondary);
}

.drive-link {
    font-size: 1.5em;
    text-decoration: underline;
    color: var(--primary);
    margin-left: 30px;
}

.pdf-link:hover,
.drive-link:hover {
    color: var(--secondary);
}

.pdf-link {
    font-size: 1.5em;
    text-decoration: none;
    color: var(--primary);
}