.footer-content {
  font-size: 1.5em;
}

footer {
  width: 100%;
  z-index: 2;
  margin: 0 !important;
  padding-bottom: 50px;
  padding-top: 10px;
}
