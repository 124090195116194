@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'EB Garamond', serif;
    scroll-behavior: smooth;
    max-width: 100%;
    /* overflow-x: hidden; */
}

.page{
    max-width: 950px;
    margin: 0 auto;
    /* background-color: var(--light); */
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar{
    /* width: 0; */
    /* background: transparent; */
}
