.portfolio-page{
    /* max-width: 950px; */
    /* margin: 0 auto; */
    /* position: relative; */
    width: 80%;
    height: auto;
    margin-left: 20%;
}

.section-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.section-button {
    text-decoration: none;
    color: var(--light);
    background-color: var(--secondary);
    padding: 5px 30px;
    margin: 5px 10px;
    border-radius: 10px;
    font-size: 1.5rem;
}

.section-button:hover {
    color: var(--primary);
    text-decoration: none;
}

.em {
    font-style: italic;
}

.back-to-top {
    display: block;
    text-decoration: none;
    z-index: 999;
    font-size: 1.5em;
    position: fixed;
    bottom: 15px;
    right: 30px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary);
    color: var(--light);
}

.back-to-top:hover{
    text-decoration: none;
    color: var(--light);
}
@keyframes bounce {
    from {
        bottom: 15px;
    }
    50% {
        bottom: 20px;
    }
    to {
        bottom: 15px;
    }
}

@media (hover:hover) {
    .back-to-top:hover {
        color: var(--secondary);
        animation-name: bounce;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
    }
}
    
